(function () {
  let burg = document.querySelectorAll('.header__burger, .header__menu');

  for (let i = 0; i < burg.length; i++) {
    burg[i].addEventListener('click', event => {
      // closeMenu();
      let nav = document.querySelectorAll('.header__burger,.header__menu');
      for (let j = 0; j < nav.length; j++) {
        nav[j].classList.toggle('active');
      }
      // document.body.classList.toggle("lock");
    });
  }

  document.addEventListener('click', event => {
    let menu = document.querySelector('.header__menu');
    let button = document.querySelector('.header__burger');
    if (!menu || !button) return;
    // If the click target is NOT the popup or any of its children, close the popup
    if (!menu.contains(event.target) && event.target !== menu && !button.contains(event.target) && event.target !== button && menu.classList.contains('active')) {
      console.log(menu.classList.contains('active'));
      let nav = document.querySelectorAll('.header__burger,.header__menu');
      for (let j = 0; j < nav.length; j++) {
        nav[j].classList.toggle('active');
      }
    }
  });
})();
